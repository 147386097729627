import React from 'react';

import { PAGES } from '../../utils/utils';
import Home from '../../components/en/home';
import Layout from '../../components/layout';
import SEO from '../../components/seo';

const HomePage = () => (
  <Layout lang="en" page={PAGES.ACCUEIL}>
    <SEO title="Home" lang="en" />
    <Home />
  </Layout>
);

export default HomePage;
