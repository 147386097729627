import { Link } from 'gatsby';
import React from 'react';

const Home = () => {
  return (
    <>
      <div id="slogan" className="effect1">
        <h1>
          <span className="accent4">There.</span>{' '}
          <span className="accent3">With you.</span>{' '}
        </h1>
      </div>

      <div id="boutons">
        <ul>
          <li className="bouton">
            <Link to="/en/team">
              <div className="gros-bouton">
                <img
                  src="/img/lemire-avocats-equipe.jpg"
                  width="240"
                  height="145"
                  alt="Team"
                  title="Lemire Avocats Team"
                />
                <div className="ligne-vert"></div>
                <h2>Team</h2>
              </div>
            </Link>
          </li>

          <li className="bouton">
            <Link to="/en/expertise">
              <div className="gros-bouton">
                <img
                  src="/img/lemire-avocats-competences.jpg"
                  alt="Expertise"
                  title="Lemire Avocats Expertise"
                />
                <div className="ligne-vert"></div>
                <h2>Expertise</h2>
              </div>
            </Link>
          </li>

          <li className="bouton">
            <Link to="/en/firm">
              <div className="gros-bouton">
                <img
                  src="/img/lemire-avocats-cabinet.jpg"
                  alt="Firm"
                  title="Lemire Avocats Firm"
                />
                <div className="ligne-vert"></div>
                <h2>Firm</h2>
              </div>
            </Link>
          </li>
        </ul>
      </div>

      <div id="info">
        <div id="intro">
          <h1 className="slogan">
            We find the <br />
            <span className="accent">best solution</span> <br /> for you in
            respect of <br />
            your rights and needs.
          </h1>
          <Link
            className="callToAction"
            to="/en/firm"
            title="More on Lemire Lemire avocats LLP"
          >
            Read More
          </Link>
        </div>

        <div id="bg_effect"></div>
      </div>
    </>
  );
};

Home.propTypes = {};

export default Home;
